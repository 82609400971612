import React, { useState } from 'react'
import "./services.css"



const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTap=(index) => {
      setToggleState(index);
    }
   
    return (
        <section className="services section" id="services">
            <h2 className="section__title">Hizmetlerim</h2>
            <span className="section__subtitle">Ne vaat ediyorum ?</span>

            <div className="services__container container grid">

                <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title"> <strong> Sizin için React ile geliştirilmiş çok hızlı web sitesi geliştirebilirim. </strong></h3>
                    </div>
                    <span className="services__button" onClick={()=>toggleTap(1)}>Daha Fazlası <i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal" }>
                        <div className="services__modal-content">
                            <i onClick={()=> toggleTap(0)} className="uil uil-times services__modal-close"></i>
                            <h3 className="services__modal-title"> <strong>  React ile web geliştirme </strong></h3>
                            <p className="services__modal-description">Çevik web tasarımları</p>
                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon "></i>
                                    <p className="services__modal-info"> Redux toolkit kullanarak web sitesi geliştirebilirim. </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">React ile geliştirilmiş projelerinize reactrouter'ı entegre edebilrim.</p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Reactstrap kullanarak modern web sayfası tasarlaya  bilirim.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-arrow services__icon"></i>
                        <h3 className="services__title"> <strong> Sizin için geliştirdiğiniz projelerin API test analizlerini yapabilirim.</strong></h3>
                    </div>
                    <span className="services__button"  onClick={()=>toggleTap(2)}>Daha Fazlası<i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal" }>
                        <div className="services__modal-content">
                            <i className="uil uil-times services__modal-close" onClick={()=> toggleTap(0)}></i>
                            <h3 className="services__modal-title"> <strong>API test analizi</strong> </h3>
                            <p className="services__modal-description">Selenium ile test analizleri.</p>
                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Python kullanarak testlerinizi yapabilirim.</p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Selenium kütüphanesini kullanarak API testlerinizi yapabilirim.</p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Selenium IDE kullanarak manuel ve test otomasyonlarınızı yapabilirim.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-edit services__icon"></i>
                        <h3 className="services__title"><strong> Sizin için WordPress ile geliştirilmiş statik web siteleri yapabilirim.</strong></h3>
                    </div>
                    <span className="services__button" onClick={()=>toggleTap(3)}>Daha Fazlası<i className="uil uil-arrow-right services__button-icon"></i></span>

                    <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal" }>
                        <div className="services__modal-content">
                            <i className="uil uil-times services__modal-close" onClick={()=> toggleTap(0)}></i>
                            <h3 className="services__modal-title"><strong>WordPress Tasarımları</strong></h3>
                            <p className="services__modal-description">WordPress ile tasarlanmış statik sayfalar.</p>
                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">WordPress kullanarak şirketinizi yansıtan statik web siteleri yapabilirim.</p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">WordPress kullanarak modern ve şık web tasarımları yapabilirim.</p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">WordPress kullanarak sizin için yazı ve makalelerinizi paylaşabileceğiniz web sayfası tasarlayabilirim.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


            </div>

        </section>
    )
}

export default Services