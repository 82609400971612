import React, { useState, useRef } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";



// import required modules
import { EffectFlip, Pagination, Navigation } from "swiper";

const WorkItem = ({ item }) => {
  const [toggleState, setToggleState] = useState(0);


  const toggleTap = (index) => {
    setToggleState(index);
  }

  return (
    <div className="work__card" key={item.id}>
      <img src={item.image} alt="" className='work__img' />
      <h3 className="work__title">{item.title}</h3>
      <button className="work__button" onClick={() => toggleTap(1)}>
        Tümünü Gör <i className="bx bx-right-arrow-alt work__button-icon"></i>
      </button>
      <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
        <div className="services__modal-content">
          <i onClick={() => toggleTap(0)} className="uil uil-times services__modal-close"></i>
          <Swiper
            effect={"flip"}
            grabCursor={true}
            pagination={true}
            navigation={true}
            modules={[EffectFlip, Pagination, Navigation]}
            className="mySwiper"
          >
            {item.images.map((image) => (
              <SwiperSlide key={image}>
                <img src={image} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

    </div>


  )
}

export default WorkItem