import React, { useEffect, useState } from 'react'
import { projectsNav, ProjectsData } from "./Data"
import WorkItem from './WorkItem';


const Works = () => {
    const [item, setItem] = useState({name: "tümü"}); 
    const [projects, setProjects] = useState([]);
    const [active, setActive] = useState(0);

    useEffect(() => {
      if(item.name === "tümü"){
        setProjects(ProjectsData);
      }
      else{
        const newProjects =ProjectsData.filter((projects)=>{
            return projects.category.toLocaleLowerCase() === item.name;
        });
        setProjects(newProjects);

      }
    }, [item]);

    const handleClick =(e,index) => {
        setItem({name:e.target.textContent.toLocaleLowerCase()});
        setActive(index);
      
    };
    
    return (
        <>
            <div className="work__filters">
                {
                    projectsNav.map((item, index) => {
                        return (
                            <button
                             onClick={(e) => {
                                handleClick(e, index); 
                            }} 
                            className={`${active===index ? 'active-work' : '' } work__item  `}
                            key={index}>
                                {item.name}
                            </button>
                        )
                    })
                }
            </div>
            <div className="work__container container grid">
                {projects.map((item) =>{
                    return (
                        <WorkItem item={item} key={item.id}/>
                    )
                })}
            </div>
        </>

    )
}

export default Works