import React, { useState } from 'react'
import "../qualification/qualification.css"

const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTap = (index) => {
        setToggleState(index);
    }
    return (
        <section className="qualification section">
            <h2 className="section__title">Kariyerim</h2>
            <span className="section__subtitle">Eğitim ve İş Hayatım</span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div className={toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
                        onClick={() => toggleTap(1)}>
                        <i className="uil uil-graduation-cap qualification__icon"></i>
                        Eğitimim
                    </div>
                    <div className={toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
                        onClick={() => toggleTap(2)}>
                        <i className="uil uil-briefcase-alt qualification__icon"></i>
                        Deneyimim
                    </div>
                </div>

                <div className="qualification__sections">
                    <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content "}>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title"><strong>REACT İle Web Programcılığı </strong></h3>
                                <span className="qualification__subtitle"> BTK AKADEMİ </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    2023
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>


                        <div className="qualification__data">
                            <div>
                            </div>
                            <div>
                                <div>
                                    <h3 className="qualification__title"><strong>API ve API Testi </strong></h3>
                                    <span className="qualification__subtitle"> BTK AKADEMİ</span> 
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i>
                                        2022
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title"> <strong>PL/SQL, Oracle <br /> Veritabanı <br /> Programlama</strong></h3>
                                <span className="qualification__subtitle"> BTK AKADEMİ</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    2022
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>

                            </div>
                            <div>
                                <h3 className="qualification__title"><strong>Sıfırdan İleri Seviye Python Programlama</strong></h3>
                                <span className="qualification__subtitle"> BTK Akademi</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    2018
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title"><strong>Front End Developer</strong> </h3>
                                <span className="qualification__subtitle"> ÖZTÜRK YAZILIM </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    05/2022-01/2023
                                </div>
                            </div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>

                            </div>
                        </div>
                        <div className="qualification__data">

                            <div>
                            </div>

                            <div>
                                <h3 className="qualification__title"><strong>WordPress Tasarım</strong></h3>
                                <span className="qualification__subtitle"> Bionluk </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    04/2023
                                </div>

                            </div>

                        </div>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">API Test</h3>
                                <span className="qualification__subtitle">Bionluk</span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt">04/2022</i>
                                </div>


                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Qualification