import db1 from "../../assets/db1.jpg"
import db2 from "../../assets/db2.jpg"
import db3 from "../../assets/db3.jpg"
import db4 from "../../assets/db4.jpg"
import bkf1 from "../../assets/bkf1.jpg"
import bkf2 from "../../assets/bkf2.jpg"
import bkf3 from "../../assets/bkf3.jpg"
import bkf4 from "../../assets/bkf4.jpg"
import mn1 from "../../assets/mn1.jpg"
import mn2 from "../../assets/mn2.jpg"
import mn3 from "../../assets/mn3.jpg"
import mn4 from "../../assets/mn4.jpg"
import wp1 from "../../assets/wp1.jpg"
import wp2 from "../../assets/wp2.jpg"
import wp3 from "../../assets/wp3.jpg"
import lg1 from "../../assets/lg1.jpg"
import lg2 from "../../assets/lg2.jpg"
import lg3 from "../../assets/lg3.jpg"








export const ProjectsData = [
    {
        id: 1,
        image: db1,
        images: [db1,db2,db3,db4],
        title: "WordPress Project",
        category: "wordpress",
    },
    {
        id: 2,
        image: bkf1,
        images: [bkf1,bkf2,bkf3,bkf4],
        title: "Book App",
        category: "react",
    },
    {
        id: 3,
        image: lg1,
        images: [lg1,lg2,lg3],

        title: "Logo Tasarım",
        category: "tasarım",
    },
    {
        id: 4,
        image: mn1,
        images: [mn1,mn2,mn3,mn4],
        title: "Menü App",
        category: "react",
    },
    {
        id: 5,
        image: wp1,
        images: [wp2,wp3,wp1],
        title: "WordPress araç tanıtım",
        category: "wordpress",
    },
];
export const projectsNav=[
    {
        name:"tümü",
    },
    {
        name:"wordpress",
    },
    {
        name:"react",
    },
    {
        name:"tasarım",
    },
]