import React from 'react'

const Info = () => {
    return (
        <div className="about__info grid">
            <div className="about__box">
                <i className='bx bx-award about__icon'></i>
                <h3 className="about__title">Deneyimim</h3>
                <span className="about__subtitle">+1 Yıl</span>
            </div>

            <div className="about__box">
                <i className='bx bx-briefcase-alt-2 about__icon' ></i>
                <h3 className="about__title">Projelerim</h3>
                <span className="about__subtitle">+ 20 Proje</span>
            </div>

            <div className="about__box">
                <i className='bx bx-support about__icon' ></i>
                <h3 className="about__title">Destek</h3>
                <span className="about__subtitle">7/24 Destek</span>
            </div>
        </div>
    )
}

export default Info