import React, { useState } from 'react'
import "./footer.css"
const Footer = () => {
    const [activeNav, setActiveNav] = useState("#home");
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Uğurcan ŞEN</h1>
                <ul className="footer__list">
                    <li>
                        <a href="#about" onClick={() => setActiveNav("#about")}  className="footer__link">Hakkımda</a>
                    </li>
                    <li>
                        <a href="#portfolio" onClick={() => setActiveNav("#portfolio")} className="footer__link">Projelerim</a>
                    </li>
                    <li>
                        <a href="#testimonial" onClick={() => setActiveNav("#testimonial")} className="footer__link">Referanslarım</a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a href={"https://www.instagram.com/"} className="footer__social-link" target="_blank"><i className="bx bxl-instagram"></i></a>
                    <a href={"https://www.linkedin.com/in/u%C4%9Furcan-%C5%9Fen-293506256/"} className="footer__social-link" target="_blank"><i className="bx bxl-linkedin"></i></a>
                    <a href={"https://twitter.com/"} className="footer__social-link" target="_blank"><i className="bx bxl-twitter"></i></a>
                    <a href={"https://wa.me/5434854156"} className="footer__social-link" target="_blank">  <i className="bx bxl-whatsapp"></i> </a>
                </div>

                <span className="footer__copy">&#169; Uğurcan Şen</span>

            </div>
        </footer>
    )
}

export default Footer