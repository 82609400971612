import React from 'react'

const Social = () => {
  // let instaurl="https://www.instagram.com/";
  // let linkdinurl ="https://www.linkedin.com/in/u%C4%9Furcan-%C5%9Fen-293506256/"
  // let twitterurl ="https://twitter.com";
  // let whatsappurl ="https://wa.me/5434854156";
  return (
    <div className="home__social">
      <a href={"https://www.instagram.com/"} className="home__social-icon" target="_blank"><i className="uil uil-instagram"></i></a>
      <a href={"https://www.linkedin.com/in/u%C4%9Furcan-%C5%9Fen-293506256/"} className="home__social-icon" target="_blank"><i className="uil uil-linkedin-alt"></i></a>
      <a href={"https://twitter.com/" }className="home__social-icon" target="_blank"><i className="uil uil-twitter-alt"></i></a>
      <a href={"https://wa.me/5434854156"} className="home__social-icon" target="_blank">  <i className="uil uil-whatsapp"></i> </a>
    </div>
  )
}

export default Social