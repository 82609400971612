import ts1 from "../../assets/ts1.jpg"
import ts2 from "../../assets/ts2.jpg"
import ts3 from "../../assets/ts3.jpg"





export const Data = [
    {
      id: 1,
      image: ts1,
      title: "Mehmet Akif ÖZTÜRK",
      description:
        "Takım çalışması konusunda yetenekli, problem çözme becerisi yüksek keyif alarak çalıştığım takım arkadaşı.",
    },
    {
      id: 2,
      image: ts2,
      title: "Emrullah Toprak",
      description:
        "İstenilen kalite ve hızda projeyi teslim ettiği ayrıca güler yüzlü yaklaşımı için kendisine teşekkür ederim. ",
    },
    {
      id: 3,
      image:ts3,
      title: "Elif Ceylan",
      description:
        "Profesyonel yaklaşımı ve titiz çalışması tercih sebebimiz oldu tekrar çalışmak dileği ile...",
    },
  ];
